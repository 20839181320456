import React, {useEffect,useState} from 'react';
import AttachmentContainer from '../../../../components/commons/assignmentAndQuiz/AttachmentContainer';
import IconTitle from '../../../../components/commons/IconTitle';
import ClassTag from '../../Components/ClassTag';
import {useParams, Link } from 'react-router-dom';
import { withTranslation, useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import CourseTitle from '../../Components/CourseTitle';
import InstructorName from '../../Components/InstructorName';
import StudentCount from '../../Components/StudentCount';
import styles from './CourseView.module.css'
import MyCourseCard from '../../Components/MyCourseCard';
import CourseAction from './CourseAction';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { getCourseDetails,getAllCourses, clearCourses, deleteCourse, updateCourseStatus } from '../../../../redux/actions/courses';
import { CircularProgress } from "@mui/material";
import { thumbnailList } from '../../../../redux/constants/commonFunctions';
import CreateEditCourseModal from '../../Modals/CreateEditCourseModal';
import CourseActionModal from '../../Modals/CourseActionModal';
import Modal from '../../../../components/commons/Modal';
import {  
    handleErrorMessage,
  } from "../../../../redux/actions/classes";
import ReactHtmlParser from 'react-html-parser'; 
import CourseContent from '../../../CourseLecturePlan/Components/CourseContent';
import ExploreCourseCard from '../../../CourseLecturePlan/Components/ExploreCourseCard';
import WelcomeToCourseModal from "../../../../apps/CourseLecturePlan/Modals/WelcomeToCourseModal"
import { Button as ButtonMUI } from '@mui/material';
import { downloadCertificate } from '../../../../redux/actions/courseReview';
import KnowYourInstructorCard from '../../Components/KnowYourInstructorCard';

function CourseView(props){
    // const user = JSON.parse(localStorage.getItem('userData'))?.user_subtype;4
    const user  = useSelector((state)=>state?.profile?.user?.user_subtype);
    const courses = useSelector((state)=> state?.courses?.publicCourses) || [];

    const { t } = useTranslation();
    const { id } = useParams()
    const dispatch = useDispatch();
    const history = useHistory()
    const [isLoadingInfo,setLoadingInfo] = useState(true);
    const [dropDownAction, setDropDownAction] = useState("delete");
    const [showActionModal, setShowActionModal] = useState(false);
    
    const [isLoadingPDF,setLoadingPDF] = useState(false);
    
    
    // this is for the explore courses
    const [isLoading,setLoading] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    const data = props?.data?.courses?.currentCourse;

    const profileId = useSelector((state) => state?.profile?.profileId) || 0;
    const classesOwnedByTeacher = useSelector((state) => state.classes.classes).filter(
        (item) => item.class_owner == profileId
    );
    
    const handleRedirect = () => {
      history.push({
        pathname: '/course/create',
        state: {
          'edit': true,
          'courseID': data.id,
          'currentCourse': data,
          'courseType': data.public ? 'Public':'Private',
          'courseTitleType': "Edit",
          'classes': classesOwnedByTeacher,
        }
      })
    }

    const handleCertificateDownload = () => {
      setLoadingPDF(true);
      dispatch(downloadCertificate(data?.id)).then(()=>setLoadingPDF(false));
    }

    const styleButtonMUI = {
      display: "flex",
      alignItems: "center",
      borderRadius: 50,
      backgroundColor: "#ED1E24",
      borderColor: "#ED1E24",
      color: "#fff",
      fontWeight: 'bold',
      fontSize: '1rem',
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
      marginRight: "auto",
      padding: '0.55rem 0.4rem',
      "&:hover": {
        backgroundColor: "#ED1E24",
        borderColor: "#ED1E24",
        color: "#fff",
      },
      "&.Mui-disabled": {
        backgroundColor: "#f6f6f6",
        color: "#959595",
        borderColor: "#f6f6f6",
      },
      // '@media (max-width: 500px)': {
      //   height: "20px",
      //   padding: "5px 11px 6px 11px",
      // },
    };


    const classTag = []
    data?.classes.map((item,index)=>{
        classTag.push(item.name);
    });

    var PricingDetail = null;
    var EnrolledBox = null;
    const url = window.location.pathname.split('/').pop();

    const handleShowActionModal = (type) => {
        setDropDownAction(type);
        setShowActionModal(true);
    }


    const handleActionModal = () => {
        
        if(dropDownAction == "delete"){
          if(data?.members_count == 0) {
            dispatch(deleteCourse(data?.id)).then(()=>setShowActionModal(false));
            setShowActionModal(false);
            history.push('/user');
          }else{
            dispatch(handleErrorMessage(t("courseMonetisation.cannotDeleteCourse")));
            setShowActionModal(false);        
          }
          
        } else {
          dispatch(updateCourseStatus(data?.id, !data?.active, false));
          setShowActionModal(false);
        }
      }
    
      const handleCancel = () => {
        setDropDownAction('');
        setShowActionModal(false)
      } 
    

    PricingDetail = <CourseAction data={data} handleRedirect={handleRedirect} showModal={showModal} handleShowActionModal={handleShowActionModal}/>

    if(data?.is_enrolled){
        EnrolledBox =(
          <Link to={`/course/stream/${data?.id}`} target={`_blank`} className={styles.link}>
            <div className={styles.enrolled}>
              <p> {t("courseMonetisation.resumeCourse")} </p>
            </div>
          </Link>
        );
    } 

    useEffect(() => {
        // get the course details
        if(!isLoadingInfo){
            setLoadingInfo(true);
        }
        if(!isLoading){
            setLoading(true);
        }
        dispatch(getCourseDetails(id)).then(()=>setLoadingInfo(false));
        // dispatch(getAllCourses(null, null, null, null, false)).then(()=>setLoading(false));
        // getCourseDetailById(id);
        dispatch(getAllCourses(null, true, null, null, null, null, 10)).then(()=>setLoading(false));
        return () => {
            dispatch(clearCourses());
          }

    },[url]);


    useEffect(() => {
      if(data?.showWelcomeModal) {
        setShowWelcomeModal(true);
      }
    }, [data])

    



    return (
      <div className={styles.CourseView}>
        {showModal && (
          <CreateEditCourseModal
            key={data?.id}
            courseId={data?.id}
            currentMediaFiles={data?.files}
            currentUrls={data?.urls}
            courseTitleType={t("common.edit")}
            coursePriceValue={data?.price}
            isCourseFreeEdit={data?.price === 0 ? "FREE" : "PAID"}
            courseName={data?.name}
            courseSubtitle={data?.subtitle}
            courseDescription={data?.description}
            classTags={data?.classes}
            courseType={data?.public ? t("common.Public") : t("common.Private")}
            closeModal={handleRedirect}
            show={showModal}
            courseView={true}
            isActive={data?.active}
          />
        )}
        {showActionModal && (
          <Modal
            card={
              <CourseActionModal
                type={dropDownAction}
                onCancel={handleCancel}
                onConfirm={handleActionModal}
              />
            }
          ></Modal>
        )}
        {showWelcomeModal && (
          <Modal
            card={
              <WelcomeToCourseModal
                welcome={true}
                courseID={data?.id}
                courseName={data?.name}
                closeModal={() => setShowWelcomeModal(false)}
              />
            }
            onOutsideClick={() => setShowWelcomeModal(false)}
          />
        )}
        <div className={styles.banner}>
          {data?.thumbnail ? (
            <img src={data?.thumbnail} alt="CourseBanner" />
          ) : (
            <img
              src="/Image/CourseMonitization/CourseViewBanner.png"
              alt="CourseBanner"
            />
          )}
        </div>

        {isLoadingInfo ? (
          <div className={styles.loader}>
            <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
          </div>
        ) : (
          <div className={styles.CourseDetails}>
            <div className={styles.CourseDetailsLeft} >
              {/* <CourseTitle title={"Botany’s beginner’s course"}/> */}

              <div className={styles.course_top_section} >

                <div style={styles.top_section_left} >

              <IconTitle
                title={data?.name}
                type={"ASSIGNMENT"}
                img={"/Image/CourseMonetisation/booklet-line-2.svg"}
                notClickable={true}
                upcomingItem={"true"}
                heading={"true"}
              />

              <h4 className={styles.caption}>{data?.subtitle}</h4>
              <InstructorName instructor={data?.owner?.name} />
              <StudentCount count={data?.members_count} />
              <p className={styles.descTitle}>{t("common.description")} </p>
              <p className={styles.desc}>
                {ReactHtmlParser(data?.description)}
              </p>
              <ClassTag courseClasses={classTag} />
              {/* <button onClick={handleModal}>Edit</button> */}
              </div>

              {/* <div className={styles.top_section_right}>
                <KnowYourInstructorCard />

              </div> */}
              </div>

              <CourseContent
                content={data?.section}
                isEnrolled={data?.is_enrolled}
                progress={data?.progress_bar}
                totalLecture={data?.total_lecs}
                courseLength={data?.course_mins}
              />
            </div>

            <div className={styles.CourseDetailsRight}>
              {data?.section?.length > 0 && EnrolledBox}
              {(data?.owner?.id == profileId || data?.section?.length > 0) &&
                PricingDetail}

              {data?.progress_bar === 100 && <div className={styles.download_card}>
                  <ButtonMUI
                    variant="outlined"
                    disableRipple
                    onClick={isLoadingPDF ? () => null : handleCertificateDownload}
                    sx={styleButtonMUI}
                    fullWidth
                    startIcon={
                      isLoadingPDF ? <CircularProgress sx={{ color: "#FFF" }} size={20} /> :
                      <img style={{width: '1.5rem'}} src="/Image/CourseMonetisation/download-white.svg" />
                    }
                  >
                    <span style={{fontWeight: '700', fontSize: '1rem'}}>{t("courseMonetisation.certificate")}</span>
                  </ButtonMUI>
              </div>}

              <div className={styles.exploration}>
                <CourseTitle title="Explore" />

                <div className={styles.exploreCards}>
                  {courses?.length > 0 &&
                    courses?.map((course, ind) => (
                      <ExploreCourseCard
                        key={course?.id}
                        ind={ind}
                        id={course?.id}
                        thumbnail={course?.thumbnail ?? thumbnailList[ind % 13]}
                        public={course?.public}
                        name={course?.name}
                        price={course?.price}
                        ownerName={course?.owner?.name}
                        memberCount={course?.memberCount}
                        classes={course?.classes}
                        courseLength={course?.course_mins}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Exploration courses */}

        {/* <div className={styles.exploration}>
                    <CourseTitle title="Explore" />
                    
                        <div className={styles.exploreCards}>
                            {isLoading ?  
                                <div className={styles.loader}>
                                    <CircularProgress sx={{color: "#ED1E24"}} size={50} />
                                </div> : 
                                // <MyCourseCard courses={courses} isOwner={true} />
                                courses?.length>0 && courses?.map((course, ind) => 
                                (<MyCourseCard ind={ind} id={course?.id} thumbnail={course?.thumbnail ?? thumbnailList[ind % 13]} public={course?.public} name={course?.name} price={course?.price} ownerName={course?.owner?.name} memberCount={course?.memberCount} classes={course?.classes} isEnrolled={true} />)) 
                            
                            
                            }
                                                            
                                 
                        </div>
                   
                </div>    */}

        {/* {!data?.is_enrolled ? null
            : 
             <div className={styles.attachmentContainer}>
                <p> {t("courseMonetisation.CourseContent")} </p>
                {data?.files?.length > 0 &&
                    (data?.files?.map((file) =>
                      <AttachmentContainer
                        key={file?.id}
                        assignmentFileType="FILE"
                        fileName={`${file?.media
                            ?.split("/")
                            .pop()
                            .substr()}`}
                        file={file?.media}
                        pageType=""
                        id={file?.id}
                      />
                    ))
                  }

                 {data?.urls?.length > 0 &&
                    (data?.urls?.map((link) =>
                      <AttachmentContainer
                      key={link?.id}
                      assignmentFileType="LINK"
                      linkName={link?.url
                        ?.split("/")
                        .pop()
                        .substr()}
                      link={link?.url}
                      pageType=""
                      id={link?.id}
                      />
                    ))
                  }
            </div>
        } */}
      </div>
    );

}

const mapStateToProps = (state, props) => {
    return {
      data: state
    };
  };
  
//   const mapDispatchToProps = (dispatch) => {
//     return {
//       const getCourseDetailById: (id) => dispatch(getCourseDetails(id)),

//     };
//   };
  

export default withTranslation()(
    connect(mapStateToProps)(CourseView)
  );