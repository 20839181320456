import React, { useState, useRef } from "react";
import styles from "./AddSignatureModal.module.css";
import { useTranslation } from "react-i18next";
import TopBar from "../../Dashboard/shared/ui/Modal/TopBar";
import { Tabs, Tab, Typography } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../../components/assignmentAndQuiz/Button/Button";
import { useDispatch } from "react-redux";
import { handleErrorMessage } from "../../../redux/actions/classes";
import { validateFileSize } from "../../../redux/constants/commonFunctions";
import useWindowWidth from "../../../hooks/useWindowWidth";

const AddSignatureModal = (props) => {
  const { t } = useTranslation();

  const width = useWindowWidth();

  const isMobile = width <= 800; 


  const [currentTab, setCurrentTab] = useState(0);
  const [sign, setSign] = useState("");

  const uploadButtonRef = useRef();
  const dispatch = useDispatch();

  let canvasWidth = isMobile ? window.innerWidth * 0.8 : window.innerWidth * 0.338;
  let canvasHeight = isMobile ? window.innerHeight * 0.6 : window.innerHeight * 0.38;

  const handleTabChange = (event, newValue) => {
    console.log("Tab handle", event.target, newValue);
    setCurrentTab(newValue);
  };

  const signatureRef = useRef(null);

  const clearCanvas = () => {
    signatureRef.current.clear();
  };

  const saveSignature = async () => {
    if (currentTab === 0) {
      const dataURL = signatureRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      console.log(dataURL); // You can use this data URL to display or save the image
      setSign(dataURL);
      // props.getFileData(signatureRef.current.files[0])

      const blob = await fetch(dataURL).then((res) => res.blob());

      // Create a file from the Blob
      const file = new File([blob], "signature.png", { type: "image/png" });

      props.getFileData(file);

      props.getData(dataURL);
      props.onClose();
    }

    if (currentTab === 1) {
      // props.getData(URL.createObjectURL(e.target.files[0]));
      props.getData(sign);
      props.onClose();
    }
  };

  const handleUpload = (e) => {
    let file = e.target.files[0];

    let extension = file.name.split(".").pop().toLowerCase();
    console.log("inside handleUpload", file, extension);
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
        let errorMessage = validateFileSize(file, 2048);
        if (errorMessage) {
          dispatch(handleErrorMessage(errorMessage));
        } else {
          setSign(URL.createObjectURL(file));
          props.getFileData(file);
        }
        break;
      default:
        dispatch(
          handleErrorMessage(
            `.${extension} ${t("courseMonetisation.filesNotSupported")}`
          )
        );
    }
  };

  const sketchStyles = {
    border: "0.0625rem solid #9c9c9c",
    borderRadius: "0.25rem",
  };

  const tabStyle = {
    textTransform: "none",
    color: "#A6A6A6",
    font: "1rem Montserrat",
    fontWeight: 500,
    padding: "0px 10px",
    "@media (max-width: 500px)": {
      font: "13px Montserrat",
      padding: "0px 10px",
    },
  };

  console.log("canvasWidth", window.innerWidth, canvasWidth);

  return (
    <div className={styles.main}>
      <TopBar title={t("courseMonetisation.sign")} close={props.onClose} />
      <div className={styles.middle_container}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#ED1E24",
            },
          }}
          sx={{
            "& button.Mui-selected": {
              color: "#ED1E24",
            },
            "& button:focus": {
              color: "#ED1E24",
            },
            "& button.Mui-disabled": {
              backgroundColor: "#f6f6f6",
              color: "#959595",
              borderColor: "#f6f6f6",
            },
          }}
        >
          <Tab sx={tabStyle} label={t("courseMonetisation.draw")} />
          {/* <Tab sx={tabStyle} label={t("courseMonetisation.type")} /> */}
          <Tab sx={tabStyle} label={t("courseMonetisation.upload")} />
        </Tabs>

        {currentTab === 0 && (
          <TabPanel
            sx={{
              border: "1px solid red",
            }}
          >
            <div className={styles.tab_container}>
              <div className={styles.reset_icon} onClick={clearCanvas}>
                <img
                  src="/Image/CourseMonetisation/reset-red.svg"
                  alt="reset"
                />
              </div>
              <SignatureCanvas
                ref={signatureRef}
                penColor="blue"
                canvasProps={{
                  width: canvasWidth,
                  height: canvasHeight,
                  className: "sigCanvas",
                }}
                backgroundColor={"#ebebeb"}
                style={{ width: "100%", height: "100%",  touchAction: 'none', }}
              />
            </div>
          </TabPanel>
        )}
        {currentTab === 5 && (
          <TabPanel>
            {/* <div style={{ width: canvasWidth, height: canvasHeight }}></div> */}
            <div className={styles.tab_container}></div>
          </TabPanel>
        )}
        {currentTab === 1 && (
          <TabPanel>
            {/* <div style={{ width: canvasWidth, height: canvasHeight }}></div> */}
            <div className={styles.tab_container}>
             {<div className={styles.upload_box}>
                <input
                  onChange={(e) => {
                    handleUpload(e);
                  }}
                  type="file"
                  accept="image/*"
                  className={styles.file_upload}
                  ref={uploadButtonRef}
                />
                {/* <img
                  src="/Image/CreateCourse/upload.svg"
                  alt="Photos"
                  className={styles.upload_img}
                /> */}
                {!sign && <>
                  <p className={styles.upload_text}>
                    {t("courseMonetisation.selectImage")}
                  </p>
                  <p className={styles.support_txt}>
                    {t("createCourse.supportJpegPngFiles")}
                  </p>
                </>}
              </div>}

              {sign && (
                <img
                  src={
                    typeof sign === "string" ? sign : URL.createObjectURL(sign)
                  }
                  className={styles.thumbnail_pic}
                />
              )}

              {sign && (
                <div
                  className={styles.change_thumbnail}
                  onClick={() => uploadButtonRef?.current?.click()}
                >
                  <img
                    src={"/Image/DashImage/camera.svg"}
                    className={styles.camera_img}
                    alt=""
                    title={t("createCourse.changeThumbnail")}
                  />
                </div>
              )}
            </div>
          </TabPanel>
        )}
        <div className={styles.button_container}>
          <Button label={"Cancel"} clickHandler={props.onClose} />
          <Button label={"Save"} clickHandler={saveSignature} />
        </div>
      </div>
    </div>
  );
};

export default AddSignatureModal;

function TabPanel(props) {
  return (
    <Typography component="div" role="tabpanel">
      {props.children}
    </Typography>
  );
}
