import axios from "axios";
import {
  errorMessageFromResponse,
  formatName,
} from "../constants/commonFunctions";
import { GET_CODE_SUGGESTIONS, BOOST_MY_PROFILE, BOOST_MY_POST, REMOVE_EXISTING_PAYMENT_ORDER, ADD_PUBLICATION, ADD_TRAINING, ADD_RESEARCH, ADD_PROJECT, UPDATE_PUBLICATION, UPDATE_RESEARCH, UPDATE_TRAINING, UPDATE_PROJECT, GET_PROFILE_DATA_EDIT  } from "../constants/actionTypes";
import { store } from "../store";
import { handleSuccessMessage } from "./success";
import { handleErrorMessage } from "./classes";
import { PAYMENT_SUCCESS_API, PAYMENT_FAILURE_API, ADD_PUBLICATION_API, UPDATE_PUBLICATION_API, ADD_TRAINING_API, UPDATE_TRAINING_API, ADD_RESEARCH_API, UPDATE_RESEARCH_API, ADD_PROJECT_API, UPDATE_PROJECT_API, TOGGLE_SECTION_VISIBILITY_API, ADD_OPEN_TO_WORK_API, EDIT_OPEN_TO_WORK_API, DELETE_OPEN_TO_WORK_API, UPDATE_SIGNATURE_API } from "../constants/apis";
import i18n from "../../i18nextConf";

export function fetchProfileDataEdit(action, token, id) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${token}`,
    };
    let lang = window.localStorage.getItem("i18nextLng");
    if (lang == "en-US") {
      lang = "en";
    }
    return (
      axios
        // .get(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/${id}`, { headers: headers })
        .get(
          `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/${id}/?target=No_Translation`,
          { headers: headers }
        )
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            const user = { ...res.data.data };
            user.profile_completion_status = res.data.profile_completion_status;
            
            // user = {...user, profile_completion_status: res.data.profile_completion_status}
            user.name = formatName(user.name);
            dispatch({
              type: action,
              payload: user,
            });
            return { val: true };
          }
          // return { val: false, message: `Some error occured - ${res.data.message}` };
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        })
    );
  };
}

export function fetchProfileDataView(action, token, id) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${token}`,
    };
    let lang = window.localStorage.getItem("i18nextLng");
    if (lang == "en-US") {
      lang = "en";
    }
    return (
      axios
        // .get(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/${id}`, { headers: headers })
        .get(
          `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/${id}/?target=${lang}/`,
          { headers: headers }
        )
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            const user = res.data.data;
            user.name = formatName(user.name);
            user.connection_state = res.data?.connection_state;
            user.connection_id = res.data?.connection_id;
            dispatch({
              type: action,
              payload: user,
            });
            return { val: true };
          }
          // return { val: false, message: `Some error occured - ${res.data.message}` };
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        })
    );
  };
}

export function updateProfileData(action, data) {
  const profileId = store.getState()?.profile?.user?.profile_id;
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/${profileId}/`,
        data,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.status == "1") {
          const value = Object.fromEntries(data.entries());
          dispatch({
            type: action,
            payload: value,
          });
          dispatch(handleSuccessMessage("Information updated successfully!"));
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function fetchSearchResultsData(action, url) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(url, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          const payloadData = {
            data: res.data.data,
            nextSearch: res.data.next,
            prevSearch: res.data.previous,
          };

          dispatch({
            type: action,
            payload: payloadData,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export const getEnterpriseCodes = () => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/enterprise_codes/`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        const codeSuggestions = response.data.data;
        dispatch({ type: GET_CODE_SUGGESTIONS, payload: codeSuggestions });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
    // .catch(async (error) => {
    //   const errorMsg = error?.response?.data?.error?.detail || error?.response?.data?.error || "Network Error";
    //   dispatch(handleErrorMessage(errorMsg));
    //   return error;
    // });
  };
};


// ------------------ boostPost -----------

// export const boostPost = (postId) => {
//   const token = store.getState()?.profile?.user?.token;

//   const data = new FormData();
//   data.append("boosted", 1);
  
//   try {
//     axios({
//       method: "put",
//       url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/${postId}/`,
//       data: data,
//       headers: {
//         "content-type": "multipart/form-data",
//         Authorization: `Token ${parsedUserData.token}`,
//       },
//     }).then((response) => {
//       console.log("theResponse-boost-post", response)
//     });
//   } catch (error) {
//     alert("Something went wrong, Please try again.");
//   }
// };

export const boostPost = (postId) => {
  const token = store.getState()?.profile?.user?.token;
  const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/post/${postId}/`;

  return async (dispatch) => {
    let data = new FormData();
    data.append("boosted", 1);

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-boost', response);
          dispatch({
            type: BOOST_MY_POST,
            payload: response.data.data, // Payload
          });

          // dispatch(
          //   handleSuccessMessage(
          //     i18n.t("assignmentSuccessMessages.assignmentUpdatedSuccessfully")
          //   )
          // );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const boostProfile = (profileId) => {
  const token = store.getState()?.profile?.user?.token;
  const url = `${process.env.REACT_APP_API_BASE_URL}api/v1/boost-profile/`;

  return async (dispatch) => {
    let data = new FormData();
    data.append("profile_id", profileId);

    await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-boostProfile', response);
          dispatch({
            type: BOOST_MY_PROFILE,
            payload: response.data.data, // Payload
          });

          // dispatch(
          //   handleSuccessMessage(
          //     i18n.t("assignmentSuccessMessages.assignmentUpdatedSuccessfully")
          //   )
          // );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const boostPaymentSuccessful =  (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "put",
      url: PAYMENT_SUCCESS_API,
      data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
          dispatch({ type: REMOVE_EXISTING_PAYMENT_ORDER, payload: response.data.data });
          dispatch(
            handleSuccessMessage(
              i18n.t("boostProfile.paymentSuccessful")
            )
          )            
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export const boostPaymentFailed = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "put",
      url: PAYMENT_FAILURE_API,
      data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        dispatch({ type: REMOVE_EXISTING_PAYMENT_ORDER, payload: response.data.data });
        dispatch(
          handleErrorMessage(
            i18n.t("boostProfile.paymentFailed")
          )
        )                

      })
      .catch(async (error) => {
        // dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
} 


export const addPublication = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const url = ADD_PUBLICATION_API;

  return async (dispatch) => {

    await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-patent', response);
          dispatch({
            type: ADD_PUBLICATION,
            payload: response.data, // Payload
          });

          dispatch(
            handleSuccessMessage(
              i18n.t("profile.publicationAddedMessage")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const addTraining = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const url = ADD_TRAINING_API;

  return async (dispatch) => {

    await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-patent', response);
          dispatch({
            type: ADD_TRAINING,
            payload: response.data, // Payload
          });

          dispatch(
            handleSuccessMessage(
              i18n.t("profile.trainingAddedMessage")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const addResearch = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const url = ADD_RESEARCH_API;

  return async (dispatch) => {

    await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-patent', response);
          dispatch({
            type: ADD_RESEARCH,
            payload: response.data, // Payload
          });

          dispatch(
            handleSuccessMessage(
              i18n.t("profile.researchAddedMessage")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};


export const addProject = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const url = ADD_PROJECT_API;

  return async (dispatch) => {

    await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-patent', response);
          dispatch({
            type: ADD_PROJECT,
            payload: response.data, // Payload
          });

          dispatch(
            handleSuccessMessage(
              i18n.t("profile.projectAddedMessage")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};


export const updatePublication = (data, id) => {
  const token = store.getState()?.profile?.user?.token;
  const url = `${UPDATE_PUBLICATION_API}${id}/`;

  return async (dispatch) => {

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-patent', response);
          dispatch({
            type: UPDATE_PUBLICATION,
            payload: response.data, // Payload
          });

          dispatch(
            handleSuccessMessage(
              i18n.t("profile.publicationUpdatedMessage")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const updateResearch = (data, id) => {
  const token = store.getState()?.profile?.user?.token;
  const url = `${UPDATE_RESEARCH_API}${id}/`;

  return async (dispatch) => {

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-patent', response);
          dispatch({
            type: UPDATE_RESEARCH,
            payload: response.data, // Payload
          });

          dispatch(
            handleSuccessMessage(
              i18n.t("profile.researchUpdatedMessage")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const updateTraining = (data, id) => {
  const token = store.getState()?.profile?.user?.token;
  const url = `${UPDATE_TRAINING_API}${id}/`;

  return async (dispatch) => {

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-patent', response);
          dispatch({
            type: UPDATE_TRAINING,
            payload: response.data, // Payload
          });

          dispatch(
            handleSuccessMessage(
              i18n.t("profile.trainingUpdatedMessage")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const updateProject = (data, id) => {
  const token = store.getState()?.profile?.user?.token;
  const url = `${UPDATE_PROJECT_API}${id}/`;

  return async (dispatch) => {

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-patent', response);
          dispatch({
            type: UPDATE_PROJECT,
            payload: response.data, // Payload
          });

          dispatch(
            handleSuccessMessage(
              i18n.t("profile.projectUpdatedMessage")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};


export const toggleSectionVisibility = (data, profileId) => {
  const token = store.getState()?.profile?.user?.token;
  const url = `${TOGGLE_SECTION_VISIBILITY_API}${profileId}/`;

  return async (dispatch) => {

    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-patent', response);
          // dispatch({
          //   type: UPDATE_PROJECT,
          //   payload: response.data, // Payload
          // });

          dispatch(
            handleSuccessMessage(
              i18n.t("profile.sectionVisibilityStatusUpdate")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// -------------- openToWork --------------

export const saveOpenToWork = (data, isEdit, openToWorkId = "") => {
  const token = store.getState()?.profile?.user?.token;
  const url = isEdit ? `${EDIT_OPEN_TO_WORK_API}${openToWorkId}` : ADD_OPEN_TO_WORK_API;

  return async (dispatch) => {

    await axios({
      method: isEdit ? "put" : "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-openToWork', response);
          
          dispatch(fetchProfileDataEdit(
            GET_PROFILE_DATA_EDIT,
            token,
            JSON.parse(localStorage.userData).profile_id
          ))


          dispatch(
            handleSuccessMessage(
              i18n.t("openToWork.profileUpdated")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};


export const deleteOpenToWork = (openToWorkId = "") => {
  const token = store.getState()?.profile?.user?.token;
  const url = `${DELETE_OPEN_TO_WORK_API}${openToWorkId}`;

  return async (dispatch) => {

    await axios({
      method: "delete",
      url: url,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-openToWork', response);
          
          dispatch(fetchProfileDataEdit(
            GET_PROFILE_DATA_EDIT,
            token,
            JSON.parse(localStorage.userData).profile_id
          ))


          dispatch(
            handleSuccessMessage(
              i18n.t("openToWork.OTWRemoved")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ------------- Signature Update ---------------


export const updateSignature = (sign, profileId) => {
  console.log("payload Sign", sign, typeof(sign));
  const token = store.getState()?.profile?.user?.token;
  const url = `${UPDATE_SIGNATURE_API}${profileId}`;

  const data = new FormData();
    data.append("signature", sign);

  return async (dispatch) => {
    await axios({
      method: "put",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          console.log('theResponse-openToWork', response);
          dispatch(fetchProfileDataEdit(
            GET_PROFILE_DATA_EDIT,
            token,
            JSON.parse(localStorage.userData).profile_id
          ))
          // dispatch(
          //   handleSuccessMessage(
          //     i18n.t("openToWork.profileUpdated")
          //   )
          // );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};