import React, { useState, useEffect } from "react";
import styles from "./css/CreateQuizPage.module.css";
import { useTranslation } from "react-i18next";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import IconTitle from "../../components/commons/IconTitle";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DiscardDeleteAssignmentModal from "./Modals/DiscardDeleteAssignmentModal";
import Modal from "../../../src/components/commons/Modal";
import QuizSettings from "./Components/QuizSettings";
import AssignedToModal from "./Modals/AssignedToModal";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Theme,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import {
  getClassDetails,
  handleErrorMessage,
  changeActiveSection,
} from "../../redux/actions/classes";
import DropDown from "./Components/DropDown";
import {
  getAllQuizTemplates,
  getIndividualQuiz,
} from "../../redux/actions/enhancedQuiz";
import TooltipWrapper from "../CourseMonetization/Components/TooltipWrapper";
import { createQuiz, updateQuiz } from "../../redux/actions/enhancedQuiz";
import { clearCourses } from "../../redux/actions/courses";

const CreateQuizPage = () => {
  const isMobile = window.innerWidth < 768;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const actionType = location?.state?.actionType;
  const routeType = location?.state?.routeType;
  const isCourse = location?.state?.isCourse; // is the quiz being attached to a course
  const classLoggedIn = [useSelector((state) => state?.classes?.class)];
  const currentClassName = useSelector((state) =>
    routeType == "class"
      ? state?.classes?.class?.name
      : state?.groups?.group?.name
  );
  const quizTemplates =
    useSelector((state) => state?.enhancedQuiz?.quizTemplates) ?? [];

  const currentQuizId = actionType === "edit" && location?.state?.quizId;
  const isOwner = location?.state?.isOwner ?? false;
  const existingQuiz =
    useSelector((state) => state?.enhancedQuiz?.individualQuiz) ?? {};

  // ---- useState section -----
  const [quizTitle, setQuizTitle] = useState(
    actionType == "edit" ? existingQuiz?.title : ""
  );
  const [quizInstructions, setQuizInstructions] = useState(
    actionType == "edit" ? existingQuiz?.details : ""
  );
  const [assignedToStatus, setAssignedToStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [maxMarks, setMaxMarks] = useState(
    actionType === "edit" ? existingQuiz?.max_marks : ""
  );
  const [dueDate, setDueDate] = useState(
    actionType === "edit" ? existingQuiz?.due_date : ""
  );
  const [dueTime, setDueTime] = useState(
    actionType === "edit" ? existingQuiz?.timestamp : ""
  );
  const [dateTime, setDateTime] = useState(
    actionType === "edit"
      ? existingQuiz?.due_date
      : moment(Date()).format("YYYY-MM-DDTHH:mm:ssZ")
  );
  const [isLock, setIsLock] = useState(
    actionType === "edit" ? existingQuiz?.is_lock : false
  );
  // const [selectedTemplateID, setSelectedTemplateID] = useState(
  //   isCourse ? location?.state?.quizTemplateID : []
  // );
  const [selectedTemplateID, setSelectedTemplateID] = useState(
    actionType == "edit"
      ? location?.state?.quizTemplateID
      : isCourse
      ? location?.state?.quizTemplateID
      : ''
  );
  const [chosenSettings, setChosenSettings] = useState(null);

  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showSettingModal, setShowSettingModal] = useState(false);

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  // ---- useEffect section -----
  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllQuizTemplates()).then(() => setIsLoading(false));
  }, []);

  // console.log("existingQuiz", existingQuiz);
  // console.log("chosenSettingsOnPage", chosenSettings);
  // console.log("Selected Quiz Template", selectedTemplateID);
  // console.log("DueDate and time", dateTime)
  // console.log("DueDate", dueDate)

  useEffect(() => {
    if (actionType == "edit") {
      dispatch(getIndividualQuiz(currentQuizId)).then(() =>
        setIsLoading(false)
      );
    }
  }, []);

  useEffect(() => {
    if (actionType == "edit") {
      setIsLoading(true);
      setQuizTitle(existingQuiz?.title);
      setQuizInstructions(existingQuiz?.details);
      setMaxMarks(existingQuiz?.max_marks);
      setDueDate(existingQuiz?.due_date?.slice(0,11));
      setDueTime(existingQuiz?.due_date?.slice(11))
      setDateTime(existingQuiz?.due_date);
      setIsLock(existingQuiz?.is_lock);
      setChosenSettings({
        order: existingQuiz?.question_randomized,
        attempts: existingQuiz?.reattempt,
        reveal: existingQuiz?.show_answers,
        isTimed: existingQuiz?.is_timed,
        duration: existingQuiz?.duration,
        hours: Math.floor(existingQuiz?.duration / 60),
        minutes: Math.floor(existingQuiz?.duration % 60),
        seconds:
          (existingQuiz?.duration - Math.floor(existingQuiz?.duration)) * 60,
      });
      setIsLoading(false);
    }
  }, [existingQuiz]);

  const discardModalClose = () => {
    setShowDiscardModal(false);
  };

  const closeSettingsModal = () => {
    setShowSettingModal(false);
  }

  const discardFunction = () => {
    setQuizTitle(null);
    setQuizInstructions(null);
    if (isCourse) {
      dispatch(clearCourses()).then(() => {
        history.goBack();
      });
    } else {
      history.push({
        pathname: `/${routeType}/${params.id}`,
        state: { activeSection: "Assignments" },
      });
    }
  };

  // ----- assignedToModal functions ------
  const getMaxMarks = (mm) => {
    maxMarkCheck(mm);
  };
  const maxMarkCheck = (mm) => {
    switch (mm) {
      case null:
      case undefined:
      case "":
        dispatch(
          handleErrorMessage(t("errorMessageTranslations.enterValidNumber"))
        );
        setMaxMarks(mm);
        return false;
      default:
        if (!isNaN(mm)) {
          if (mm <= 0) {
            dispatch(
              handleErrorMessage(
                t("errorMessageTranslations.marksCannotBeNegative")
              )
            );
            setMaxMarks(mm);
            return false;
          } else if (mm > 2000000000) {
            dispatch(
              handleErrorMessage(
                t("errorMessageTranslations.maxMarksCannotBeGreaterThan2B")
              )
            );
            setMaxMarks(mm);
            return false;
          } else {
            setMaxMarks(mm);
            return true;
          }
        }
    }
  };

  const getDueDate = (dd, dt) => {
    setDueDate(moment(dd).format("YYYY-MM-DDT"));
    setDueTime(moment(dt).format("HH:mm:ssZ"));
    setDateTime(
      `${moment(dd).format("YYYY-MM-DDT")}${moment(dt).format("HH:mm:ssZ")}`
    );
  };

  const getLockStatus = (lc) => {
    setIsLock(lc);
  };

  const getAssignToStatus = (value) => {
    setAssignedToStatus(value);
  };

  const getQuizSettings = (data) => {
    setChosenSettings(data);
  };

  console.log("quizStuff", {
    quizInstructions,
    quizTitle,
    assignedToStatus,
    maxMarks,
    isLock,
    dueDate,
    dueTime,
    dateTime,
    quizTemplates,
    selectedTemplate: selectedTemplateID,
    chosenSettings,
  });

  const colortheme = createTheme({
    palette: {
      primary: { main: "#ED1E24" },
    },
  });

  const menuItemStyles = {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.024px",
    textTransform: "capitalize",
  };

  // ------- create quiz handler ----------
  const handleCreateQuiz = () => {
    let quizInputData = {
      title: quizTitle,
      details: quizInstructions,
      due_date: dateTime,
      is_lock: isLock,

      is_timed: chosenSettings.isTimed,
      duration: chosenSettings.duration,
      question_randomized: chosenSettings.order,
      reattempt: chosenSettings.attempts,
      show_answers: isCourse ? true : chosenSettings.reveal,
      isPublished: isCourse ? false : true,  
    };

    if (actionType == "edit") {
      quizInputData.temp_id = parseInt(selectedTemplateID);
    } else {
      quizInputData.assign_all = isCourse ? true : assignedToStatus === "true";
      quizInputData.template = parseInt(selectedTemplateID);
    }

    if (actionType !== "edit" && routeType == "group") {
      quizInputData.group_id = params.id;
    } else if (actionType !== "edit" && routeType == "class") {
      quizInputData.class_id = params.id;
    } else if (actionType !== "edit" && isCourse) {
      quizInputData.section_id = location?.state?.sectionID;
    }

    console.log("finalObj", quizInputData);

    if (
      quizInputData.title.trim() == "" ||
      quizInputData.due_date == "" ||
      quizInputData.assign_all == "" ||
      quizInputData.question_randomized == null ||
      quizInputData.show_answers == null
    ) {
      dispatch(
        handleErrorMessage(t("errorMessageTranslations.requiredFields"))
      );
    } else if (true) {
      if (false) {
        // setIsLoadingBtn(true)
        // dispatch(updateAssignment(quizInputData, routeType )).then(()=>{
        //   setIsLoadingBtn(false)
        //   history.push({
        //     pathname: `/${routeType}/${params.id}`,
        //     state: { activeSection: "Assignments" },
        //   });
        // });
      } else {
        if ( !isCourse &&
          quizInputData.due_date <
          moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
        ) {
          dispatch(
            handleErrorMessage(
              t("errorMessageTranslations.dueDateGreaterThanCurrentDate")
            )
          );
        } else if (actionType == "edit") {
          setIsLoadingBtn(true);
          dispatch(updateQuiz(existingQuiz?.id, quizInputData)).then(() => {
            setIsLoadingBtn(false);
            history.push({
              pathname: `/${routeType}/${params.id}`,
              state: { activeSection: "Quizzes" },
            });
          });
        } else {
          setIsLoadingBtn(true);
          dispatch(createQuiz(quizInputData)).then(() => {
            setIsLoadingBtn(false);
            let path = isCourse ? `/course/content/${location?.state?.courseID}` : `/${routeType}/${params.id}`
            history.push({
              pathname: path,
              state: { activeSection: "Quizzes" },
            });
          });
        }
      }
    }
  };


  return (
    <>
      {isLoading ? (
        <div className={styles.loader_style}>
          <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
        </div>
      ) : (
        <div className={styles.main_container}>
          {showAssignModal && (
            <Modal
              card={
                <AssignedToModal
                  assignmentClass={classLoggedIn}
                  assignmentType="QUIZ"
                  changeEdit={() => setShowAssignModal(!showAssignModal)}
                  submitForm={handleCreateQuiz}
                  getDueDate={getDueDate}
                  maxMarks={
                    quizTemplates[
                      quizTemplates?.findIndex(
                        (template) => template.id === selectedTemplateID
                      )
                    ]?.marks
                  }
                  lockStatus={getLockStatus}
                  actionType={actionType}
                  assignment={existingQuiz}
                  isLoadingBtn={isLoadingBtn}
                  getAssignToStatus={getAssignToStatus}
                />
              }
            />
          )}
          {showDiscardModal && (
            <Modal
              card={
                <DiscardDeleteAssignmentModal
                  modalMessage={t("quiz.quizDiscardMessage")}
                  modalLabel={t("common.discard")}
                  assignmentType="QUIZ"
                  changeEdit={discardModalClose}
                  discardFunction={discardFunction}
                />
              }
            />
          )}
          {showSettingModal && (
            <Modal
              card={
                <QuizSettings
                  getQuizSettings={getQuizSettings}
                  chosenSettings={chosenSettings}
                  actionType={actionType}
                  closeSettingModal = {closeSettingsModal}
              />
              }
            />
          )}
          <h5
            className={styles.back_btn}
            onClick={() => {
              setShowDiscardModal(true);
            }}
          >
            <img src="/Image/assignment/back_arrow.svg" alt="" />
            &nbsp; &nbsp;{currentClassName || t("courseMonetisation.goBackBtn")}
          </h5>
          <div className={styles.feed_container}>
            <div className={styles.title_container}>
              <IconTitle
                title={t("quiz.quiz")}
                img="/Image/assignment/White/Quiz.svg"
                notClickable={true}
                type="ASSIGNMENT"
              />

              {isMobile ?
                    <img
                      className={styles.label_icon}
                      src="/Image/assignment/settings2.svg"
                      onClick={() => {
                        setShowSettingModal(true);
                      }}
                  />
              :
                <Button
                  type="solid"
                  label={t("assignment.assign")}
                  // clickHandler={() => setShowAssignModal(!showAssignModal)}
                  clickHandler={() => {
                    if (quizTitle.trim() == "") {
                      dispatch(handleErrorMessage(t("errorMessageTranslations.requiredFields")));
                    } else if (!selectedTemplateID) {
                      dispatch(handleErrorMessage(t("enhancedQuiz.selectQuizTemplate")));                      
                    } else if (isCourse) {
                      handleCreateQuiz();                    
                    } else {
                      setShowAssignModal(!showAssignModal);
                    }
                  }}
                />
              }
            </div>

            <div className={styles.form_settings_container}>
              {/* form container */}
              <div className={styles.form_container}>
                <div className={styles.form}>
                  <p className={styles.input_label} for="quizTitle">
                    <div className={styles.input_title_container}>
                      <img
                        className={styles.label_icon}
                        src="/Image/assignment/Title.svg"
                      />
                      {t("quiz.quizTitle")}*
                    </div>
                  </p>
                  <input
                    type="text"
                    className={styles.input_field}
                    placeholder={t("enhancedQuiz.quizPlaceholder")}
                    value={quizTitle}
                    onChange={(e) => {
                      setQuizTitle(e.target.value);
                    }}
                  />

                  {/* ----- add quiz template dropdown */}
                  <p className={styles.input_label} for="quizTitle">
                    <div className={styles.input_title_container}>
                      <img
                        className={styles.label_icon}
                        src="/Image/assignment/Add attachment.svg"
                      />
                      {t("enhancedQuiz.addQuizTemplate")}*{" "}
                      <TooltipWrapper title={t("enhancedQuiz.pickTemplate")}>
                        <img
                          src="/Image/Icons/Iicon.svg"
                          className={styles.tooltip_icon}
                        />
                      </TooltipWrapper>
                    </div>
                  </p>

                  {/* ------------ add the dropdown here--------- */}
                  {console.log('inside loc templateId', location?.state?.quizTemplateID)}
                  <ThemeProvider theme={colortheme}>
                    <Select
                      value={
                        quizTemplates[
                          quizTemplates?.findIndex(
                            (template) => template.id === selectedTemplateID
                          )
                        ]
                      }
                      fullWidth
                      // disabled={isCourse === true}
                      // disabled={!location?.state?.isCreatedTemplate}
                      displayEmpty
                      sx={{
                        borderRadius: "25px",
                        height: "40px",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                      onChange={(e) => {
                        setSelectedTemplateID(e.target.value?.id);
                      }}
                    >
                      <MenuItem sx={menuItemStyles} disabled value="">
                        <em>{t("enhancedQuiz.selectQuizTemplates")}</em>
                      </MenuItem>
                      {quizTemplates?.map((item) => (
                        <MenuItem
                          key={item.id}
                          className={styles.text}
                          value={item}
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </ThemeProvider>

                  <h3 for="quizInstructions" className={styles.input_label}>
                    <div className={styles.input_title_container}>
                      <img
                        className={styles.label_icon}
                        src="/Image/assignment/Instructions.svg"
                      />
                      {t("assignment.instructions")}
                    </div>
                  </h3>
                  <textarea
                    type="text"
                    className={styles.input_field1}
                    placeholder={t("assignment.instructionsPlaceholder")}
                    value={quizInstructions}
                    onChange={(e) => {
                      setQuizInstructions(e.target.value);
                    }}
                  ></textarea>
                </div>
                {isMobile ?
                  <div className={styles.mob_btn}>
                    <Button
                      type="solid"
                      label={t("assignment.assign")}
                      // clickHandler={() => setShowAssignModal(!showAssignModal)}
                      clickHandler={() => {
                        if (quizTitle.trim() == "") {
                          dispatch(handleErrorMessage(t("errorMessageTranslations.requiredFields")));
                        } else if (!selectedTemplateID) {
                          dispatch(handleErrorMessage(t("enhancedQuiz.selectQuizTemplate")));                      
                        } else if (isCourse) {
                          handleCreateQuiz();                    
                        } else {
                          setShowAssignModal(!showAssignModal);
                        }
                      }}
                    />
                  </div>
                  :''
                }
              </div>
              {/* settings container */}
              <div className={styles.settings_container}>
                <QuizSettings
                  getQuizSettings={getQuizSettings}
                  chosenSettings={chosenSettings}
                  actionType={actionType}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateQuizPage;
